#root {
  height: 100%;
}
.App {
  height: 100%;
  width: calc(100% - 56px); /* sidenav width is 56px so... */
  margin-left: auto;
  background-color: #0d1117;
  color: #c9d1d9;
  display: flex;
  flex-direction: column;
}
a:focus, a:hover, a:focus {
  color: inherit;
}
header {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9998;
}
header .logo img {
  width: 212px;
}
footer {
  position: relative;
  z-index: 9999;
  background-color: #161b22;
  color: #8a8a8a;
  text-align: center;
  padding: 0.5rem;
  font-size: 12px;
  font-weight: 300;
  font-family: RobotoLight, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer a {
  color: #727271;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
footer .logo {
  width: 120px;
  display: none;
}

.sidenav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100%;
  background-color: #161b22;
  border-right: 2px solid #545351;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sidenav .rs-sidenav {
  width: 56px;
}
.sidenav .rs-sidenav-default .rs-sidenav-toggle {
  position: absolute;
  top: 20px;
  right: -28px;
  z-index: 10000;
  border: none;
}
.sidenav .rs-sidenav-default {
  background-color: transparent;
}
.sidenav .rs-sidenav-collapse-in {
  width: 256px;
}
.sidenav .rs-sidenav-default .rs-sidenav-item,
.sidenav .rs-sidenav-default .rs-sidenav-item:hover {
  background-color: transparent;
  padding: .5rem 2rem;
}
.sidenav .rs-sidenav-default .rs-sidenav-item a {
  position: relative;
  color: #f0f6fc;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  text-transform: uppercase;
}
.sidenav .rs-sidenav-default .rs-sidenav-item a:hover,
.sidenav .rs-sidenav-default .rs-sidenav-item a:focus,
.sidenav .rs-sidenav-default .rs-sidenav-item a:active {
  text-decoration: none;
  color: #7b80ff;
}
.sidenav .rs-sidenav-default .rs-sidenav-item a.active {
  color: #7b80ff;
  font-weight: bold;
}
.sidenav .rs-sidenav-default .rs-sidenav-item a::before {
  display: inline;
  content: "";
  position: absolute;
  left: -10px;
  width: 0;
  height: 100%;
  background-color: #7b80ff;
  border-radius: 36px;
  transition: width .1s ease-in-out;
}
.sidenav .rs-sidenav-default .rs-sidenav-item a.active::before {
  width: 3px;
}
.sidenav .rs-sidenav-default.rs-sidenav-collapse-out .rs-sidenav-item {
  visibility: hidden;
}
.sidenav .rs-sidenav-default .rs-sidenav-toggle-button {
  background-color: #161b22 ;
  border-radius: 36px;
  border: 3px solid #545351;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}
.sidenav .rs-sidenav-default .rs-sidenav-toggle-button svg {
  color: #f0f6fc;
  font-size: 1.8rem;
}
.sidenav .rs-sidenav-default .rs-sidenav-toggle-button:hover {
  border-color: #6c6c6c;
}
.sidenav .rs-sidenav-default .rs-sidenav-toggle-button:hover svg {
  color: #7b80ff;
}
.sidenav .eagleai {
  position: absolute;
  bottom: 10px;
  left: 5px;
}
.sidenav .eagleai img {
  width: 46px;
}

.page {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.map-page {
  display: flex;
  flex-direction: column;
}
.map-page .map-container {
  flex: 1;
}
.mapboxgl-map {
  font-family: Roboto, sans-serif;
}
.mapboxgl-control-container .mapboxgl-ctrl-logo,
.mapboxgl-control-container .mapboxgl-ctrl-attrib {
  display: none;
}
.mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
  bottom: 40px;
}
.map-page .map-hover-popup .mapboxgl-popup-content {
  background-color: #1f2023;
  color: white;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  border: 1px solid #414244;
  border-radius: 10px;
  padding: 10px 15px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.map-page .map-hover-popup .mapboxgl-popup-content::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 7px;
  margin: auto;
  height: calc(100% - 20px);
  width: 2px;
  background-color: #7b80ff;
  border-radius: 36px;
}
.map-page .map-hover-popup .mapboxgl-popup-content .title {
  color: #7b80ff;
  font-weight: bold;
  white-space: nowrap;
}
.map-page .map-hover-popup .mapboxgl-popup-tip {
  display: none;
}
.map-page .map-hover-popup .mapboxgl-popup-content .property {
  font-size: 16px;
  font-weight: bold;
}
.map-page .map-hover-popup .mapboxgl-popup-content .property .label {
  font-size: 15px;
  font-weight: 500;
  color: #b7b7b7;
}
.map-page .legend-container {
  position: absolute;
  top: 120px;
  right: 10px;
}
.map-page .map-legend {
  width: fit-content;
  margin-left: auto;
  background-color: #ffffffbf;
  color: #333;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  border-radius: 3px;
}
.map-legend .legend-title {
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.map-page .map-legend .level {
  display: flex;
  align-items: center;
}
.map-page .map-legend .level-key {
  width: 20px;
  height: 30px;
  margin-right: 15px;
}
.map-page .opacity-slider {
  background-color: #ffffffbf;
  color: #28292c;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 3px;
  font-weight: bold;
}
.map-page .opacity-slider .rs-slider {
  margin: 0.5rem;
  width: 140px;
}
.map-page .opacity-slider .rs-slider .rs-slider-bar {
  height: 4px;
  border-radius: 6px;
  background-color: #a9a9a9;
}
.map-page .opacity-slider .rs-slider .rs-slider-handle::before {
  border: none;
  background-color: #f19b1b;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  top: -2px;
}
.map-page .opacity-slider .rs-slider .rs-slider-handle:hover::before,
.map-page .opacity-slider .rs-slider .rs-slider-handle:focus::before {
  box-shadow: 0 0 0 4px rgb(255 205 0 / 76%);
}
.map-page .report-container {
  position: absolute;
  z-index: 9990;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .1s ease-in-out;
}
.map-page .report-container.opened {
  width: 100%;
  height: 100%;
  background-color: rgb(24 28 35 / 90%);
}
.map-page .report {
  position: absolute;
  top: 20px;
  left: 40px;
  color: #f6f6f6;
  border-radius: 3px;
  background-color: #28292c;
  overflow: auto;
}
.map-page .report-container.opened .report {
  width: calc(100% - 80px);
  height: calc(100% - 40px);
}
.map-page .report .header {
  width: fit-content;
  min-width: 30vw;
  background-color: #28292c;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}
.map-page .report .title {
  flex: 1;
  text-align: center;
  padding: 0 1rem;
  font-size: 18px;
  font-weight: 400;
}
.map-page .report .reset-btn {
  background-color: transparent;
  font-size: 22px;
  padding: 0;
  color: white;
  display: flex;
  align-items: center;
}
.map-page .report .reset-btn:hover {
  color: #b7b7b7;
}
.map-page .report .report-btn {
  background-color: #28292c;
  color: #f19c1b;
  font-size: 14px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  margin: auto;
  padding: 5px 14px;
  border: 1px solid #f19b1b;
  border-radius: 36px;
  transition: all .1s ease-in-out;
  display: flex;
  align-items: center;
}
.map-page .report .report-btn:hover {
  background-color: #f19b1b;
  color: #28292c;
}
.map-page .report .report-btn svg {
  font-size: 18px;
  margin-right: 8px;
}
.map-page .report .images-slider {
  height: fit-content !important;
  width: 400px !important;
  border-radius: 3px;
}
.map-page .report .images-slider .rs-carousel-toolbar {
  display: none;
}
.map-page .report .images-slider .image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
}
.map-page .report-container.closed .report .body {
  display: none;
}
.map-page .report .body {
  padding: 1rem;
  display: flex;
}
.map-page .report .body .left {
  padding: 0.5rem 1rem;
}
.map-page .report .body .right {
  flex: 1;
  padding: 0.5rem 1rem;
}
.map-page .report .coordinates {
  font-size: 14px;
  font-weight: 400;
  font-family: ProductSansBlack, sans-serif;
  color: #f19c1b;
}
.map-page .report .properties {
  padding: 1rem 0;
  font-size: 16px;
  font-weight: bold;
}
.map-page .report .properties .property {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.map-page .report .properties .label {
  font-size: 12px;
  font-weight: 300;
  color: #b7b7b7;
  text-transform: uppercase;
}
.map-page .report .down-btn {
  background-color: #28292c;
  color: #f19c1b;
  font-size: 14px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  margin: 1rem auto;
  padding: 8px 16px;
  border: 1px solid #f19b1b;
  border-radius: 36px;
  transition: all .1s ease-in-out;
  display: flex;
  align-items: center;
}
.map-page .report .down-btn:hover {
  background-color: #f19b1b;
  color: #28292c;
}
.map-page .report .down-btn svg {
  font-size: 18px;
  margin-right: 8px;
}
.map-page .report .map-snapshot {
  width: 400px;
  height: 280px;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 3px;
}
.map-page .report .chart-container {
  width: 700px;
  height: 350px;
  margin: auto;
  padding: 1rem 2rem;
}
.map-page .time-slider {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);
  width: 80%;
  max-width: 764px;
  font-size: 16px;
  font-weight: 900;
  padding: 1rem;
  border-radius: 36px;
  display: flex;
  align-items: center;
}
.map-page .time-slider .slider {
  flex: 1;
  margin: 0 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-page .time-slider .slider-input {
  width: 100%;
}
.map-page .time-slider .slider-input .rs-slider-bar {
  width: 100%;
  height: 8px;
  border-radius: 6px;
  background-color: #ffffff80;
}
.map-page .time-slider .slider-input .rs-slider-handle::before {
  border: 2px solid #ffcd00;
  background-color: #7b80ff;
  width: 16px;
  height: 16px;
  margin-left: -8px;
  top: 0;
  z-index: 9999;
}
.map-page .time-slider .slider-input .rs-slider-handle:hover::before,
.map-page .time-slider .slider-input .rs-slider-handle:focus::before {
  box-shadow: 0 0 0 8px rgba(255, 205, 0, .25);
}
.map-page .time-slider .slider-input .rs-slider-handle:hover .bubble,
.map-page .time-slider .slider-input .rs-slider-handle:focus .bubble {
  background-color: #7b80ffde;
}
.map-page .time-slider .slider-input .rs-slider-graduator>ol>li::before, 
.map-page .time-slider .slider-input .rs-slider-graduator>ol>li:last-child:after {
  width: 2px;
  height: 16px;
  margin-left: -1px;
  top: -7px;
  background-color: white;
  border: none;
  border-radius: 0;
}
.map-page .time-slider .slider-input .rs-slider-graduator>ol>li:last-child:after {
  right: -1px;
} 
.map-page .time-slider .bubble-container {
  position: absolute;
  width: 90px;
  bottom: calc(100% + 40px);
  left: -45px;
  cursor: pointer;
}
.map-page .time-slider .bubble-container:after {
  position: absolute;
  content: "";
  top: 100%;
  left: calc(50% - 1px);
  width: 2px;
  height: 40px;
  background-color: white;
}
.map-page .time-slider .bubble {
  background-color: #7b80ffb0;
  color: white;
  border-radius: 28px;
  padding: 0.6rem;
  font-size: 12px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  letter-spacing: .8px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map-page .time-slider .bubble .num {
  color: #f19c1b;
  font-size: 16px;
  margin-top: 5px;
  white-space: nowrap;
}
.map-page .time-slider .bubble .num small {
  font-size: 12px;
  text-transform: lowercase;
  color: white;
}
.map-page .time-slider .bubble:hover {
  background-color: #7b80ffde;
}
.map-page .time-slider .slider-ends {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}
.map-page .time-slider .end {
  background-color: #ffffffbf;
  color: #161b22;
  padding: 0.5rem;
  border-radius: 9px;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  text-transform: uppercase;
}
.map-page .one-date {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  background-color: #ffffffbf;
  color: #161b22;
  padding: 0.5rem;
  border-radius: 9px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .8px;
}
.map-page .lnglat {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #ffffffbf;
  color: #28292c;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.map-page .lnglat svg {
  font-size: 20px;
  margin-right: 5px;
}

.home-page .land-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page.landing .land-section {
   opacity: 0;
}
.home-page .land-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgb(24 28 35 / 95%);
  transition: opacity .4s ease-in-out;
}
.home-page .land-section .content {
  width: 60%;
  max-width: 846px;
  color: #f6f6f6;
  padding: 2rem;
  border-radius: 3px;
  display: flex;
}
.home-page .land-section .content .side {
  padding: 1rem;
}
.home-page .land-section .title {
  font-size: 3rem;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.home-page .land-section .text {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 2rem;
}
.home-page .land-section .text .highlight {
  color: #f19c1b;
  font-size: 18px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
}
.home-page .land-section .steps {
  padding: 1rem;
}
.home-page .land-section .step {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
}
.home-page .land-section .step img {
  width: 56px;
  border: 4px solid #545351;
  border-radius: 50%;
  padding: 10px;
  margin-bottom: 10px;
}
.home-page .land-section .step .text {
  white-space: nowrap;
  font-family: RobotoLight, sans-serif;
}
.home-page .land-section .btns a {
  color: #f19c1b;
  font-size: 18px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  padding: 8px 20px;
  border: 1px solid #f19b1b;
  border-radius: 36px;
  white-space: nowrap;
  transition: all .1s ease-in-out;
}
.home-page .land-section .btns a:not(:last-of-type) {
  margin-right: 2rem;
}
.home-page .land-section .btns a:hover {
  text-decoration: none;
  background-color: #f19b1b;
  color: #28292c;
}

.about-page {
  background:linear-gradient(0deg, rgb(24 28 35 / 90%), rgb(24 28 35 / 90%)), url(./assets/img/beach.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
}
.about-page .sections-container {
  width: 60%;
  max-width: 846px;
  margin: 2rem auto;
}
.about-page .section {
  background-color: #24272c;
  color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 3px;
}
.about-page .section .title {
  width: fit-content;
  font-size: 40px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
}
.about-page .section .title::after {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 60%;
  height: 3px;
  border-radius: 36px;
  background-color: #7b80ff;
}
.about-page .section .text {
  font-size: 18px;
}
.about-page .section .videos {
  width: calc(100% + 4rem);
  position: relative;
  left: -2rem;
}
.about-page .section .video-container {
  margin-bottom: 2rem;
}
.about-page .section .media-container {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.about-page .section .media {
  width: 33.33%;
  text-align: center;
  margin-bottom: 2rem;
}
.about-page .section .media .link {
  width: 260px;
}
.about-page .section .media img {
  max-width: 260px;
  width: 60%;
}

.contact-page {
  background:linear-gradient(0deg, rgb(24 28 35 / 80%), rgb(24 28 35 / 80%)), url(./assets/img/world-night.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-page .sections-container {
  width: 60%;
  max-width: 846px;
  margin: 2rem auto;
}
.contact-page .section {
  background-color: #24272c;
  color: white;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 3px;
}
.contact-page .section .title {
  font-size: 40px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.contact-page .section .text {
  font-size: 18px;
}
.contact-page a {
  color: #b2a68c;
}
.contact-page a:hover,
.contact-page a:focus,
.contact-page a:active {
  color: #7b80ff;
}

.activities-page {
  background:linear-gradient(0deg, rgb(24 28 35 / 90%), rgb(24 28 35 / 90%)), url(./assets/img/activities.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
}
.activities-page .sections-container {
  width: 60%;
  min-width: 55vw;
  margin: auto;
}
.activities-page .sections-container .title {
  font-size: 40px;
  font-weight: bold;
  font-family: ProductSansBlack, sans-serif;
  line-height: 110%;
  text-transform: uppercase;
  margin: 3rem;
}
.activities-page .section {
  background-color: #24272c;
  color: white;
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 3rem;
}
.activities-page .activity {
  display: flex;
  justify-content: center;
}
.activities-page .activity .title {
  font-size: 24px;
  position: relative;
  margin: 0;
  margin-bottom: 2rem;
}
.activities-page .activity .title::after {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 0;
  width: 40%;
  height: 3px;
  border-radius: 36px;
  background-color: #7b80ff;
}
.activities-page .activity .text {
  font-size: 15px;
  line-height: 1.5;
}
.activities-page .activity .activity-img {
  position: relative;
  cursor: pointer;
}
.activities-page .activity .activity-img img {
  width: 30vw;
  max-width: 864px;
  height: 100%;
  object-fit: cover;
}
.activities-page .activity .activity-img .open {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  color: white;
  border: 1px solid #7b80ff;
  font-size: 18px;
  font-weight: bold;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activities-page .activity .activity-img .open svg {
  font-size: 30px;
  margin-right: 5px;
  color: #7b80ff;
}
.activities-page .activity .activity-img:hover .open {
  opacity: 1;
}
.activities-page .activity .activity-content {
  padding: 1rem;
}
.activities-page .slider-container {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 56px;
  width: calc(100% - 56px);
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity .2s ease-in-out;
}
.activities-page .slider-container.show {
  opacity: 1;
}
.activities-page .slider-container .images-slider {
  width: 70%;
  height: 80%;
}
.activities-page .slider-container .carousel-slider {
  overflow: visible;
  height: 100%;
}
.activities-page .slider-container .carousel-slider .slider-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
}
.activities-page .slider-container .carousel-slider .slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.activities-page .slider-container .carousel-slider .control-arrow {
  height: 50px;
  width: 50px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #545351;
  color: white;
  border-radius: 36px;
  opacity: .5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.activities-page .slider-container .carousel-slider .control-arrow:hover {
  opacity: .7;
  background-color: #545351;
}
.activities-page .slider-container .carousel-slider .control-disabled.control-arrow {
  opacity: .2;
}
.activities-page .slider-container .carousel-slider .control-next.control-arrow {
  transform: translate(150%, -50%);
}
.activities-page .slider-container .carousel-slider .control-prev.control-arrow {
  transform: translate(-150%, -50%);
}
.activities-page .slider-container .carousel-slider .control-arrow:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}
.activities-page .slider-container .carousel-slider .control-next.control-arrow:before {
  border-left: 15px solid #fff;
}
.activities-page .slider-container .carousel-slider .control-prev.control-arrow:before {
  border-right: 15px solid #fff;
}
.activities-page .slider-container .carousel-slider .control-dots {
  bottom: 0;
  transform: translateY(150%);
}
.activities-page .slider-container .carousel-slider .slide img {
  max-width: 864px;
}
.activities-page .slider-container .close {
  position: absolute;
  top: 40px;
  right: 60px;
  background-color: transparent;
  color: #c2c2c2;
  font-size: 40px;
  opacity: .7;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activities-page .slider-container .close:hover {
  opacity: 1;
}

@media (max-width: 1025px) {
  header .logo img {
    width: 168px;
  }
  .sidenav .rs-sidenav {
    width: 42px;
  }
  .App {
    width: calc(100% - 42px); /* sidenav width is 42px now, so... */
  }
  .sidenav .rs-sidenav-collapse-in {
    width: 200px;
  }
  .sidenav .rs-sidenav-default .rs-sidenav-toggle-button svg {
    font-size: 1.4rem;
  }
  .sidenav .rs-sidenav-default .rs-sidenav-item a {
    font-size: 1.2rem;
  }
  .sidenav .eagleai {
    left: 3px;
  }
  .sidenav .eagleai img {
    width: 36px;
  }
  .home-page .land-section .content {
    width: 90%;
    padding: 0;
  }
  .home-page .land-section .title {
    font-size: 40px;
  }
  .home-page .land-section .step img {
    width: 48px;
    padding: 8px;
  }
  .home-page .land-section .btns a {
    font-size: 16px;
  }
  .about-page .sections-container {
    width: 90%;
  }
  .about-page .section .title {
    font-size: 30px;
  }
  .contact-page .sections-container {
    width: 80%;
  }
  .contact-page .section .title {
    font-size: 30px;
  }
  .activities-page .slider-container {
    left: 42px;
    width: calc(100% - 42px);
  }
  .activities-page .slider-container .images-slider {
    height: 50%;
  }
  .activities-page .sections-container {
    width: 90%;
  }
  .map-page .time-slider {
    bottom: 40px;
  }
  .map-page .map-legend {
    font-size: 14px;
  }
  .map-legend .legend-title {
    font-size: 15px;
  }
  .map-page .report .header {
    min-width: 50vw;
  }
  .map-page .report .title {
    font-size: 16px;
  }
  .map-page .report .body {
    padding: 1rem 0;
  }
  .map-page .report-container.opened .report {
    width: calc(100% - 50px);
  }
  .map-page .report .images-slider .image {
    height: 300px;
  }
  .map-page .report .images-slider {
    width: 356px !important;
  }
  .map-page .report .map-snapshot {
    width: 376px;
    height: 326px;
  }
  .map-page .report .chart-container {
    width: 405px;
    height: 350px;
    padding: 0;
  }
  .map-page .report .properties .label {
    font-size: 11px;
  }
  .map-page .report .properties {
    font-size: 14px;
  }
  .map-page .report .down-btn {
    font-size: 12px;
  }
}
@media (max-width: 769px) {
  .sidenav .rs-sidenav-default .rs-sidenav-toggle {
    top: 0px;
    right: unset;
    left: 0;
  }
  .sidenav .rs-sidenav-default .rs-sidenav-toggle-button {
    width: 42px;
    padding: 0;
    border: none;
    border-radius: 0;
  }
  .sidenav .rs-sidenav-default .rs-sidenav-toggle-button svg {
    font-size: 30px;
  }
  header {
    display: none;
  }
  footer {
    padding: .5rem;
    font-size: 10px;
  }
  footer a {
    font-size: 10px;
  }
  footer .logo {
    width: 86px;
    display: inline-flex;
    margin-left: auto;
  }
  .map-page .report {
    top: 0;
    left: 0;
    border-radius: 0;
    width: 100%;
  }
  .map-page .report .header {
    width: 100%;
  }
  .map-page .report .title {
    font-size: 14px;
  }
  .map-page .report .reset-btn {
    font-size: 20px;
  }
  .map-page .report .report-btn {
    font-size: 13px;
  }
  .map-page .legend-container {
    top: 50px;
    right: 0;
    width: 100%;
  }
  .map-page .map-legend {
    padding: 0.5rem;
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
  }
  .map-legend .legend-title {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-right: 5px;
  }
  .map-legend .levels {
    flex: 1;
    padding: 0 .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .map-page .map-legend .level {
    flex: 1;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .map-page .map-legend .level-key {
    width: 100%;
    height: 16px;
    margin-right: 0;
  }
  .map-page .opacity-slider {
    margin-top: 0;
    border-top: 1px solid #c3c3c3;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  .map-page .opacity-slider .rs-slider {
    flex: 1;
    margin: 0 1rem;
  }
  .mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  .map-page .lnglat {
    bottom: 5px;
    right: 5px;
    font-size: 10px;
  }
  .map-page .lnglat svg {
    font-size: 14px;
  }
  .map-page .map-hover-popup .mapboxgl-popup-content {
    padding: 5px 10px;
  }
  .map-page .map-hover-popup .mapboxgl-popup-content::before {
    width: 2px;
    left: 5px;
  }
  .map-page .map-hover-popup .mapboxgl-popup-content .property .label {
    font-size: 12px;
  }
  .map-page .map-hover-popup .mapboxgl-popup-content .property {
    font-size: 12px;
  }
  .map-page .time-slider {
    bottom: 75px;
    width: calc(100% - 60px);
    padding: 0;
  }
  .map-page .time-slider .end {
    position: absolute;
    transform: translate(-30%,100%);
    font-size: 11px;
    padding: 0.3rem;
    border-radius: 6px;
  }
  .map-page .time-slider .end:last-of-type {
    right: 0;
    transform: translate(30%,100%);
  }
  .map-page .time-slider .slider {
    margin: 0 1rem;
  }
  .map-page .time-slider .bubble-container {
    bottom: calc(100% + 20px);
  }
  .map-page .time-slider .bubble-container:after {
    height: 20px;
  }
  .map-page .time-slider .bubble {
    font-size: 10px;
  }
  .map-page .time-slider .bubble .num {
    font-size: 14px;
  }
  .map-page .report-container.opened .report {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .map-page .report .body {
    height: 100%;
    overflow: auto;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .map-page .report .images-slider .image {
    height: 200px;
  }
  .map-page .report .images-slider {
    width: 100% !important;
  }
  .map-page .report .chart-container {
    width: 100%;
    height: 286px;
  }
  .map-page .report .map-snapshot {
    width: 100%;
    height: 218px;
  }
  .map-page .report .down-btn {
    position: absolute;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    border-radius: 0;
    background-color: #f19c1b;
    color: #28292c;
    justify-content: center;
    font-size: 14px;
  }
  .home-page .land-section .content {
    width: 100%;
    flex-direction: column;
  }
  .home-page .land-section .side-0 {
    order: 0;
  }
  .home-page .land-section .side-1 {
    order: 1;
  }
  .home-page .land-section .title {
    font-size: 35px;
  }
  .home-page .land-section .text {
    font-size: 14px;
  }
  .home-page .land-section .steps {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-page .land-section .step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-page .land-section .step img {
    width: 40px;
    padding: 6px;
  }
  .home-page .land-section .step .text {
    font-size: 12px;
  }
  .activities-page {
    padding: 2rem 0;
  }
  .activities-page .sections-container .title {
    margin: 2rem 0;
  }
  .activities-page .activity {
    flex-direction: column;
  }
  .activities-page .activity .activity-img {
    order: 0;
  }
  .activities-page .activity .activity-content {
    order: 1;
    padding: 1rem 0.5rem;
  }
  .activities-page .activity .activity-img img {
    height: 264px;
    width: 100%;
  }
  .activities-page .activity .title {
    margin: 0;
    margin-bottom: 2rem;
  }
  .activities-page .slider-container .close {
    top: 30px;
    right: 30px;
  }
  .activities-page .slider-container .carousel-slider .control-arrow {
    height: 30px;
    width: 30px;
  }
  .activities-page .slider-container .carousel-slider .control-arrow:before {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  .activities-page .slider-container .carousel-slider .control-next.control-arrow:before {
    border-left: 10px solid #fff;
  }
  .activities-page .slider-container .carousel-slider .control-prev.control-arrow:before {
    border-right: 10px solid #fff;
  }
  .about-page {
    padding: 2rem 1rem;
  }
  .about-page .sections-container {
    width: 100%;
  }
  .about-page .section {
    padding: 1rem;
  }
  .about-page .section .title {
    font-size: 25px;
  }
  .about-page .section .text {
    font-size: 14px;
  }
  .about-page .section .videos {
    width: calc(100% + 2rem);
    left: -1rem;
  }
  .about-page .section .media img {
    width: 80%;
  }
  .contact-page {
    padding: 1rem;
  }
  .contact-page .sections-container {
    width: 100%;
  }
  .contact-page .section .title {
    font-size: 22px;
  }
  .contact-page .section .text {
    font-size: 15px;
  }
}