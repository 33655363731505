@font-face {
  font-family: "Roboto";
  src: local("Roboto-Regular"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoLight";
  src: local("Roboto-Light"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "ProductSansBlack";
  src: local("ProductSans-Black"),
    url("./fonts/ProductSans/ProductSans-Black.ttf") format("truetype");
}

html, 
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  color: #f1f1f1;
}